import { gql } from "@apollo/client";

export const PROJECTS = gql`
  query Projects(
    $where: ProjectWhereInput
    $like: JSONObject
    $isIn: JSONObject
    $take: Int
    $skip: Int
  ) {
    projects(
      where: $where
      like: $like
      isIn: $isIn
      take: $take
      skip: $skip
    ) {
      data {
        id
        name
        createdDate
        locked
        ria
        contract {
          issuanceDate
        }
        statuses {
          id
          createdDate
          status {
            id
            template
            name
            tag
            progress
            order
            color
            background
            createdDate
            actions {
              id
              type
              args
              conditions {
                id
                operation
                rightOperand
                leftOperand
              }
            }
            process {
              id
              processStatuses {
                id
                name
                tag
                template
                progress
                order
              }
            }
          }
        }
        fields
        contact {
          id
          user {
            firstname
            lastname
            gender
          }
        }
        author {
          id
          firstname
          lastname
        }
        subscriptions {
          id
          status
        }
        expired
      }
      count
    }
  }
`;

export const PROJECT = gql`
  query Project($where: ProjectWhereInput) {
    project(where: $where) {
      id
      name
      ria
      statuses {
        id
        createdDate
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
          moveToNext
          createdDate
          possibleStatuses {
            order
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          actions {
            id
            type
            args
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
            theme
            processStatuses {
              id
              name
              tag
              template
              progress
              order
              color
              background
              moveToNext
              possibleStatuses {
                id
                nextStatus
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
              actions {
                id
                type
                args
                conditions {
                  id
                  operation
                  rightOperand
                  leftOperand
                }
              }
            }
          }
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        acceptContact
        acceptDigital
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
      author {
        id
        firstname
        lastname
        email
        phone
      }
    }
  }
`;

const projects = { PROJECTS, PROJECT };

export default projects;
