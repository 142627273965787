import { gql } from "@apollo/client";

export const CONTRACTS = gql`
  query Contracts(
    $where: ContractWhereInput
    $take: Int
    $skip: Int
    $orderBy: [JSONObject]
  ) {
    contracts(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      data {
        id
        url
        status
        legalState
        issuanceDate
        contentDerivative
        createdDate
        expirationDate
        expirationType
        instantiatesUri
        subjects
        name
        title
        subTitle
        alias
        scope
        type
        subType
      }
      count
    }
  }
`;

export const COUNT_CONTRACTS = gql`
  query CountContracts(
    $where: ContractWhereInput
    $gt: ContractWhereInput
    $lt: ContractWhereInput
  ) # $status: String
  {
    countContracts(where: $where, gt: $gt, lt: $lt)
  }
`;

// export const COUNT_CONTRACTS = gql`
//   query CountContracts(
//     $where: ContractWhereInput
//     $gt: ContractWhereInput
//     $lt: ContractWhereInput
//     $status: String
//   ) {
//     countContracts(
//       where: { AND: [$where, { status: $status }] }
//       gt: $gt
//       lt: $lt
//     )
//   }
// `;

export const CONTRACT = gql`
  query Contract($where: ContractWhereInput) {
    contract(where: $where) {
      id
      identifier
      url
      status
      legalState
      issuanceDate
      contentDerivative
      createdDate
      expirationDate
      expirationType
      instantiatesUri
      subjects
      name
      title
      subTitle
      alias
      scope
      type
      subType
    }
  }
`;

const contracts = { CONTRACTS, CONTRACT, COUNT_CONTRACTS };

export default contracts;
