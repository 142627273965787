import { Modal } from "antd";
import PropTypes from "prop-types";
import { FaCheck, FaChevronLeft, FaRegTrashAlt } from "react-icons/fa";
import { TYPES } from "utils/constants";
import Button from "../Button";

const ModalColors = {
  [TYPES.DEFAULT]: {
    ok: "danger",
    cancel: "primary--outlined",
    icon: <FaRegTrashAlt />,
  },
  [TYPES.INFO]: {
    ok: "primary--light",
    cancel: "primary--link",
    icon: <FaCheck />,
  },
  [TYPES.SUCCESS]: {
    ok: "success--light",
    cancel: "success--link",
  },
  [TYPES.DANGER]: {
    ok: "danger--light",
    cancel: "danger--link",
  },
  [TYPES.WARNING]: {
    ok: "warning--light",
    cancel: "warning--link",
  },
};

const Dialog = ({
  okText,
  cancelText,
  onOk,
  onCancel,
  message,
  icon,
  child,
  visible,
  type,
  ...rest
}) => (
  <Modal
    className="modal__container"
    open={visible}
    onCancel={onCancel}
    centered
    footer={
      <>
        {onCancel && (
          <Button
            type={ModalColors[type].cancel}
            onClick={onCancel}
            icon={<FaChevronLeft />}
          >
            {cancelText}
          </Button>
        )}
        <Button
          type={ModalColors[type].ok}
          onClick={onOk}
          icon={ModalColors[type].icon}
        >
          {okText}
        </Button>
      </>
    }
    {...rest}
  >
    <div className="modal__container--body">
      {icon}
      <h3>{message}</h3>
      {child}
    </div>
  </Modal>
);

Dialog.propTypes = {
  visible: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(Object.values(TYPES)),
};
Dialog.defaultProps = {
  onOk: () => {},
  type: "default",
};

export default Dialog;
