import dayjs from "dayjs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "utils/constants";

export const isMajor = dayjs().subtract(18, "years");

export const hundredYearsAgoStart = dayjs()
  .subtract(100, "year")
  .startOf("year");

export const twentySevenYearsAgoStart = dayjs()
  .subtract(27, "year")
  .startOf("year");

export const displayDate = ({
  date,
  format = DATE_TIME_FORMAT,
  defaultValue = "-",
}) =>
  date && moment(date).isValid() ? moment(date).format(format) : defaultValue;
