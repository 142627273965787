import { PlusOutlined } from "@ant-design/icons";
import { FieldArray, Formik } from "formik";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";
import { MdCheck } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Select from "shared/components/Select";

import cuid from "cuid";
import { RiAddFill, RiDeleteBinFill } from "react-icons/ri";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { COVERAGE_BENEFITS } from "utils/api/graphql/queries/coverage-benefits";
import { COVERAGE_CATEGORIES } from "utils/api/graphql/queries/coverage-categories";
import { COVERAGE_LIMIT_CATEGORIES } from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ data, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const { t: benefits } = useTranslation(
    NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT
  );
  const FORM = benefits("LIST", { returnObjects: true });

  return (
    <Formik
      initialValues={{
        insurancePlanCoverages:
          omitDeep(data, "__typename", [])?.map((coverage) => ({
            ...coverage,
            needs: { id: cuid(), ...coverage.needs },
          })) || [],
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({
        isValid,
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        ...formProps
      }) => (
        <Card
          type="simple"
          title={DETAIL.INSURANCE_PLAN_COVERAGE.TITLE}
          head
          actions={
            <Button
              type="primary--outlined"
              icon={<MdCheck />}
              onClick={handleSubmit}
              disabled={!dirty || !isEmpty(formProps.errors)}
            >
              {DETAIL.HEADER.SAVE}
            </Button>
          }
        >
          <div className="grid--1">
            <FieldArray
              name={"insurancePlanCoverages"}
              render={(arrayHelpers) => [
                values.insurancePlanCoverages.map((_, index) => [
                  <div key={`category-${index}`} className="grid--2">
                    <div className="grid--2">
                      <Select
                        type="async"
                        node={{
                          query: COVERAGE_CATEGORIES,
                          accessors: {
                            root: "coverageCategories.data",
                            label: "label",
                          },
                        }}
                        placeholder={DETAIL.INSURANCE_PLAN_COVERAGE.CATEGORY}
                        label={DETAIL.INSURANCE_PLAN_COVERAGE.CATEGORY}
                        {...bindArrayInputProps({
                          parent: "insurancePlanCoverages",
                          index: index,
                          name: "coverageCategory.id",
                          values,
                          ...formProps,
                        })}
                        filter={({ value }) => {
                          return !get(
                            values,
                            `insurancePlanCoverages`,
                            []
                          ).some(({ coverageCategory }, i) => {
                            return (
                              i !== index &&
                              coverageCategory?.id &&
                              coverageCategory.id === value
                            );
                          });
                        }}
                      />
                      <Input
                        type="number"
                        label={DETAIL.INSURANCE_PLAN_COVERAGE.NEED_LEVEL}
                        placeholder={DETAIL.INSURANCE_PLAN_COVERAGE.NEED_LEVEL}
                        {...bindArrayInputProps({
                          parent: "insurancePlanCoverages",
                          index: index,
                          name: "needs.level",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                    <div className="d-flex align--start justify--end">
                      <Button
                        type="danger"
                        key="add-row"
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        <BiTrash color={COLORS.C_WHITE} />
                        {DETAIL.INSURANCE_PLAN_COVERAGE.DELETE_CATEGORY}
                      </Button>
                    </div>
                  </div>,
                  <div
                    key={`coverage-${index}`}
                    className="grid--1 p-left-80 p-right-80"
                  >
                    <FieldArray
                      name={`insurancePlanCoverages.${index}.coverageMappings`}
                      render={(arrHelpers) => [
                        get(
                          values,
                          `insurancePlanCoverages.${index}.coverageMappings`,
                          []
                        ).map((_, i) => [
                          <div key={`coverage-${i}`} className="grid--3">
                            <Select
                              type="async"
                              node={{
                                query: COVERAGE_BENEFITS,
                                variables: {
                                  orderBy: [{ type: "ASC" }],
                                },
                                accessors: {
                                  root: "coverageBenefits.data",
                                  label: "type",
                                },
                              }}
                              placeholder={
                                DETAIL.INSURANCE_PLAN_COVERAGE.COVERAGE
                              }
                              label={DETAIL.INSURANCE_PLAN_COVERAGE.COVERAGE}
                              {...bindArrayInputProps({
                                parent: `insurancePlanCoverages.${index}.coverageMappings`,
                                index: i,
                                name: "coverage.id",
                                values,
                                ...formProps,
                              })}
                              filter={({ value }) => {
                                return !get(
                                  values,
                                  `insurancePlanCoverages`,
                                  []
                                ).some(({ coverageMappings }, parentIndex) => {
                                  return (
                                    !isEmpty(coverageMappings) &&
                                    coverageMappings.some(
                                      ({ coverage: { id } }, childIndex) => {
                                        return (
                                          (parentIndex !== index ||
                                            childIndex !== i) &&
                                          id === value
                                        );
                                      }
                                    )
                                  );
                                });
                              }}
                            />
                            <Input
                              type="number"
                              placeholder={
                                DETAIL.INSURANCE_PLAN_COVERAGE.WAITING_DAYS
                              }
                              label={
                                DETAIL.INSURANCE_PLAN_COVERAGE.WAITING_DAYS
                              }
                              {...bindArrayInputProps({
                                parent: `insurancePlanCoverages.${index}.coverageMappings`,
                                index: i,
                                name: "waitingDays",
                                values,
                                ...formProps,
                              })}
                            />
                            <div className="d-flex align--end">
                              <Button
                                type="danger--outlined"
                                key="add-row"
                                onClick={() => {
                                  arrHelpers.remove(i);
                                }}
                              >
                                <BiTrash color={COLORS.C_DANGER} size={16} />
                              </Button>
                            </div>
                          </div>,
                          <div key={`limits.${i}`}>
                            <FieldArray
                              name={`insurancePlanCoverages.${index}.coverageMappings.${i}.limits`}
                              render={(arrayHelpers) => (
                                <div className="coverage__input--row">
                                  <div className="grid--1">
                                    {get(
                                      values,
                                      `insurancePlanCoverages.${index}.coverageMappings.${i}.limits`,
                                      []
                                    ).map((_, childIndex) => (
                                      <div key={_.id} className="grid--3">
                                        <Input
                                          label={FORM.LIMIT_VALUE}
                                          placeholder={FORM.LIMIT_VALUE}
                                          {...bindArrayInputProps({
                                            parent: `insurancePlanCoverages.${index}.coverageMappings.${i}.limits`,
                                            index: childIndex,
                                            name: "value.value",
                                            values,
                                            ...formProps,
                                          })}
                                        />
                                        <Select
                                          options={Object.entries(
                                            COVERAGE_LIMIT_CATEGORIES
                                          ).map(([key, value]) => ({
                                            label:
                                              FORM.COVERAGE_LIMITS.CATEGORIES[
                                                key
                                              ],
                                            value,
                                          }))}
                                          label={FORM.LIMIT_CATEGORY}
                                          placeholder={FORM.LIMIT_CATEGORY}
                                          {...bindArrayInputProps({
                                            parent: `insurancePlanCoverages.${index}.coverageMappings.${i}.limits`,
                                            index: childIndex,
                                            name: "value.category",
                                            values,
                                            ...formProps,
                                          })}
                                        />
                                        <div
                                          onClick={() => {
                                            arrayHelpers.remove(childIndex);
                                          }}
                                        >
                                          <RiDeleteBinFill
                                            size={24}
                                            color={COLORS.C_DANGER}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div
                                    kay="add-row"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        id: cuid(),
                                      });
                                    }}
                                  >
                                    <RiAddFill
                                      color={COLORS.C_PRIMARY}
                                      size={24}
                                    />
                                  </div>
                                </div>
                              )}
                            />
                          </div>,
                        ]),
                        <Button
                          type="primary--outlined"
                          key="add-row"
                          onClick={() => {
                            arrHelpers.push({
                              id: cuid(),
                              limits: [{ id: cuid() }],
                              coverage: { id: null },
                              waitingDays: 0,
                            });
                          }}
                        >
                          <PlusOutlined color={COLORS.C_WHITE} />
                          {DETAIL.INSURANCE_PLAN_COVERAGE.ADD_COVERAGE}
                        </Button>,
                      ]}
                    />
                  </div>,
                ]),
                <Button
                  type="primary"
                  key="add-row"
                  onClick={() => {
                    arrayHelpers.push({
                      id: cuid(),
                      coverageCategory: { id: null },
                      needs: { id: cuid() },
                      coverageMappings: [
                        {
                          id: cuid(),
                          limits: [{ id: cuid() }],
                          coverage: { id: null },
                        },
                      ],
                    });
                  }}
                >
                  <PlusOutlined color={COLORS.C_WHITE} />
                  {DETAIL.INSURANCE_PLAN_COVERAGE.ADD_CATEGORY}
                </Button>,
              ]}
            />
          </div>
        </Card>
      )}
    </Formik>
  );
};

export default Detail;
