import { useLazyQuery, useQuery } from "@apollo/client";
import { Col, Row, Spin } from "antd";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiAward, FiFile, FiSearch } from "react-icons/fi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Date from "shared/components/Input/Date";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { SUBSCRIPTION_STATUSES, USER_TYPES } from "utils/constants";
import ProcessStats from "./Widgets/ProcessStats";

const Dashboard = () => {
  const { t } = useTranslation(NAME_SPACES.HOME);
  const FILTER = t("FILTER", { returnObjects: true });

  const [dateRange, setDateRange] = useState({
    quotes: { gt: undefined, lt: undefined },
    subscriptions: { gt: undefined, lt: undefined },
  });

  const { data: initialQuoteCountData, loading: initialQuoteCountLoading } =
    useQuery(graphql.queries.COUNT_QUOTES, {
      variables: { where: {} },
    });

  const {
    data: initialSubscriptionsCountData,
    loading: initialSubscriptionsCountLoading,
  } = useQuery(graphql.queries.COUNT_SUBSCRIPTIONS, {
    variables: {
      where: {
        status: SUBSCRIPTION_STATUSES.SIGNED,
      },
    },
  });

  const [
    fetchQuotes,
    { data: filteredQuoteCountData, loading: filteredQuoteCountLoading },
  ] = useLazyQuery(graphql.queries.COUNT_QUOTES);

  const [
    fetchSubscriptions,
    {
      data: filteredSubscriptionsCountData,
      loading: filteredSubscriptionsCountLoading,
    },
  ] = useLazyQuery(graphql.queries.COUNT_SUBSCRIPTIONS);

  const handleFilterClick = (values) => {
    const quoteWhere = {
      AND: [
        ...(values.process
          ? [
              {
                project: {
                  statuses: {
                    status: {
                      process: { id: values.process },
                    },
                  },
                },
              },
            ]
          : []),
        ...(values.insurancePlan
          ? [
              {
                project: {
                  subscriptions: {
                    insurancePlan: { id: values.insurancePlan },
                  },
                },
              },
            ]
          : []),
        ...(values.author
          ? [
              {
                project: {
                  author: { id: values.author },
                },
              },
            ]
          : []),
      ],
    };

    const subscriptionWhere = {
      AND: [
        {
          status: SUBSCRIPTION_STATUSES.SIGNED,
        },
        ...(values.process
          ? [
              {
                project: {
                  statuses: {
                    status: {
                      process: { id: values.process },
                    },
                  },
                },
              },
            ]
          : []),
        ...(values.insurancePlan
          ? [
              {
                project: {
                  subscriptions: {
                    insurancePlan: { id: values.insurancePlan },
                  },
                },
              },
            ]
          : []),
        ...(values.author
          ? [
              {
                project: {
                  author: { id: values.author },
                },
              },
            ]
          : []),
      ],
    };

    const variablesQuotes = {
      where: quoteWhere,
      gt: dateRange.quotes?.gt || undefined,
      lt: dateRange.quotes?.lt || undefined,
    };

    const variablesSubscriptions = {
      where: subscriptionWhere,
      gt: dateRange.subscriptions?.gt || undefined,
      lt: dateRange.subscriptions?.lt || undefined,
    };

    fetchQuotes({ variables: variablesQuotes });
    fetchSubscriptions({ variables: variablesSubscriptions });
  };

  const handleDateRangeChange = (dates) => {
    const [startDate, endDate] = dates || [];
    setDateRange({
      quotes: {
        gt: startDate
          ? { createdDate: startDate.format("YYYY-MM-DD") }
          : undefined,
        lt: endDate ? { createdDate: endDate.format("YYYY-MM-DD") } : undefined,
      },
      subscriptions: {
        gt: startDate
          ? { signedDate: startDate.format("YYYY-MM-DD") }
          : undefined,
        lt: endDate ? { signedDate: endDate.format("YYYY-MM-DD") } : undefined,
      },
    });
  };

  const quoteCountLoading =
    initialQuoteCountLoading || filteredQuoteCountLoading;
  const quoteCountData = filteredQuoteCountData || initialQuoteCountData;

  const subscriptionsCountLoading =
    initialSubscriptionsCountLoading || filteredSubscriptionsCountLoading;
  const subscriptionsCountData =
    filteredSubscriptionsCountData || initialSubscriptionsCountData;

  return (
    <>
      <Header title={t("DASHBOARD")} />
      <Card type="simple">
        <Formik
          initialValues={{ process: "", insurancePlan: "", author: "" }}
          onSubmit={handleFilterClick}
        >
          {({ setFieldValue }) => (
            <Form>
              <Row gutter={[16, 16]} align={"bottom"}>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Date
                    label={FILTER.PERIOD}
                    variant="range"
                    onChange={handleDateRangeChange}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={5}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("author", value)}
                    label={FILTER.ADVISOR}
                    node={{
                      query: graphql.queries.USERS,
                      variables: {
                        where: {
                          OR: [
                            { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                            { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
                            {
                              meta: {
                                type: USER_TYPES.BROKER_REPRESENTATIVE.type,
                              },
                            },
                            {
                              meta: {
                                type: USER_TYPES.INSURANCE_REPRESENTATIVE.type,
                              },
                            },
                            {
                              meta: { type: USER_TYPES.INTERNAL_ADVISOR.type },
                            },
                            {
                              meta: {
                                type: USER_TYPES.INTERNAL_SUPERVISOR.type,
                              },
                            },
                          ],
                        },
                      },
                      accessors: {
                        root: "users.data",
                        label: {
                          separator: " ",
                          keys: ["firstname", "lastname"],
                        },
                      },
                    }}
                    placeholder={FILTER.ADVISOR}
                    allowClear
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("insurancePlan", value)}
                    label={FILTER.SELECT_PRODUCT}
                    node={{
                      query: graphql.queries.INSURANCE_PLANS,
                      variables: {
                        where: {},
                      },
                      accessors: {
                        root: "insurancePlans.data",
                        label: "name",
                      },
                    }}
                    placeholder={FILTER.SELECT_PRODUCT}
                    allowClear
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={4}>
                  <Select
                    type="async"
                    onChange={(value) => setFieldValue("process", value)}
                    label={FILTER.SALES_JOURNEY}
                    node={{
                      query: graphql.queries.PROCESSES,
                      variables: {
                        where: {},
                      },
                      accessors: {
                        root: "processes.data",
                        label: "name",
                      },
                    }}
                    placeholder={FILTER.SELECT_SALES_JOURNEY}
                    allowClear
                  />
                </Col>
                <Col xs={24} sm={24} md={4} lg={3}>
                  <Button
                    key="done"
                    icon={<FiSearch size={20} />}
                    type="primary"
                    size={"full--width"}
                    htmlType="submit"
                  >
                    {FILTER.FILTER_LABEL}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Card>
      <div className="p-top-20" />
      <Row gutter={[16, 16]} align={"bottom"}>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            type={"count"}
            icon={<FiFile />}
            label={FILTER.NUMBER_OF_QUOTES}
            value={
              quoteCountLoading ? (
                <Spin size="small" />
              ) : (
                quoteCountData?.countQuotes || 0
              )
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <Card
            type="count"
            icon={<FiAward />}
            label={FILTER.NUMBER_OF_CONTRACTS}
            value={
              subscriptionsCountLoading ? (
                <Spin size="small" />
              ) : (
                subscriptionsCountData?.countSubscriptions || 0
              )
            }
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="p-top-20">
        <Col span={24}>
          <ProcessStats />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
