import cuid from "cuid";
import { Formik } from "formik";
import { get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { ATTACHMENT_TYPES, SCREENS } from "utils/constants";
import { bindInputProps, noSpace } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE_CATEGORY);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };

  return (
    <Formik
      initialValues={omitDeep(data, "__typename")}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.COVERAGE_CATEGORIES.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              label={FORM.VALUE}
              placeholder={FORM.VALUE}
              onKeyDown={noSpace}
              {...bindInputProps({ name: "value", ...formProps })}
            />
            <Input
              label={FORM.LABEL}
              placeholder={FORM.LABEL}
              {...bindInputProps({ name: "label", ...formProps })}
            />
            <Input
              type="number"
              label={FORM.ORDER}
              placeholder={FORM.ORDER}
              {...bindInputProps({ name: "order", ...formProps })}
            />
            <Input
              type="number"
              label={FORM.WEIGHT}
              placeholder={FORM.WEIGHT}
              {...bindInputProps({ name: "weight", ...formProps })}
            />
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              showUploadList={false}
              maxCount={1}
              onUpload={([file]) => handleFileUpload(file, setFieldValue)}
              fileList={
                isEmpty(get(formProps, "values.icon", []))
                  ? []
                  : [get(formProps, "values.icon")]
              }
            >
              <Button type="primary--link">{FORM.ICON}</Button>
              <div>
                {get(formProps, "values.icon") && (
                  <img
                    className="icon"
                    alt="icon"
                    src={get(formProps, "values.icon")}
                  />
                )}
              </div>
            </Input>
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
