import { Drawer } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Avatar from "shared/components/Avatar";
import Tabs from "shared/components/Tab";
import { NAME_SPACES } from "shared/locales/constants";
import Information from "./Tabs/Information";
import Projects from "./Tabs/Projects";

const View = ({
  contact,
  active,
  setActive,
  project,
  projects,
  drawer,
  onClose,
  onAssign,
  isAssigning,
  tracer,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTACT);
  const DRAWER = t("DRAWER", { returnObjects: true });
  const TRANSLATION = t("DETAIL", { returnObjects: true });

  const handleTabChange = (key) => {
    setActive(key);
  };

  const onCloseDrawer = () => {
    onClose();
    setActive("INFORMATION");
  };

  const TABS = [
    {
      key: "INFORMATION",
      children: (
        <Information project={project} translate={TRANSLATION} data={contact} />
      ),
      label: DRAWER.TABS.INFORMATION.TITLE,
    },
    {
      key: "PROJECTS",
      children: (
        <Projects
          translate={TRANSLATION}
          tracer={tracer}
          isAssigning={isAssigning}
          onAssign={onAssign}
          projects={projects}
        />
      ),
      label: `${DRAWER.TABS.PROJECTS.TITLE} (${projects?.count})`,
    },
  ];

  return (
    <Drawer
      title={
        <>
          <h2>{DRAWER.TITLE}</h2>
          <div>
            <Avatar
              mode={"text"}
              size={"small"}
              value={`${get(contact, "user.firstname", "")?.substring(
                0,
                1
              )}${get(contact, "user.lastname", "")?.substring(0, 1)}`}
            />
            <span>
              {get(contact, "user.firstname")} {get(contact, "user.lastname")}
            </span>
          </div>
        </>
      }
      placement="right"
      onClose={onCloseDrawer}
      open={drawer}
      className="contact__drawer"
      contentWrapperStyle={{
        width: "500px",
      }}
    >
      <Tabs items={TABS} activeKey={active} onChange={handleTabChange} />
    </Drawer>
  );
};
export default View;
