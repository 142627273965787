import { filter, find, get, isEmpty } from "lodash";
import moment from "moment";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import {
  CONTACT_POINT_SYSTEM,
  DATE_FORMAT,
  RELATIONSHIP,
} from "utils/constants";

const Information = ({ data, project, translate }) => {
  const children = filter(get(data, "relatedPersons", []), {
    relationship: RELATIONSHIP.CHILD,
  });
  const spouse = get(data, "relatedPersons", []).find(
    ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
  );
  return (
    <div className="contact__drawer--container">
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.ADHERENT}</span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.LAST_NAME}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "user.lastname")}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.FIRST_NAME}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "user.firstname")}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.BIRTH_DATE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {moment(get(data, "user.birthDate")).format(DATE_FORMAT)}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.EMAIL}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(
              find(get(data, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.EMAIL,
              }),
              "value",
              "---------"
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.ZIP_CODE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(
              find(get(data, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.ZIP_CODE,
              }),
              "value",
              "---------"
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.PHONE}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(
              find(get(data, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.PHONE,
              }),
              "value",
              "---------"
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.ALREADY_CLIENT}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(data, "fields.isClient", false) ? (
              <FaRegCircleCheck size={24} color="#41AD6A" />
            ) : (
              <RxCross2 size={24} color="#EB5757" />
            )}
          </span>
        </div>
        <div className="contact__drawer--container__child--info">
          <span className="contact__drawer--container__child--info__title">
            {translate.CSS_CLIENT}
          </span>
          <span className="contact__drawer--container__child--info__content">
            {get(project, "fields.info.cssBeneficiary", false) ? (
              <FaRegCircleCheck size={24} color="#41AD6A" />
            ) : (
              <RxCross2 size={24} color="#EB5757" />
            )}
          </span>
        </div>
      </div>
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.SPOUSE}</span>
          {spouse ? (
            <FaRegCircleCheck size={20} color="#41AD6A" />
          ) : (
            <RxCross2 size={20} color="#EB5757" />
          )}
        </div>
        {spouse && (
          <div className="contact__drawer--container__child--info">
            <span className="contact__drawer--container__child--info__title">
              {translate.SPOUSE_BIRTH_DATE}
            </span>
            <span className="contact__drawer--container__child--info__content">
              {moment(get(spouse, "identity.user.birthDate")).format(
                DATE_FORMAT
              )}
            </span>
          </div>
        )}
      </div>
      <div className="contact__drawer--container__child">
        <div className="contact__drawer--container__child--header">
          <span>{translate.CHILDREN}</span>
          {isEmpty(children) ? (
            <RxCross2 size={20} color="#EB5757" />
          ) : (
            <FaRegCircleCheck size={20} color="#41AD6A" />
          )}
        </div>
        {!isEmpty(children) && (
          <>
            <div className="contact__drawer--container__child--info">
              <span className="contact__drawer--container__child--info__title">
                {translate.CHILDREN_COUNT}
              </span>
              <span className="contact__drawer--container__child--info__content">
                {children.length}
              </span>
            </div>
            {children.map((_, index) => (
              <div
                key={`child-${index}`}
                className="contact__drawer--container__child--info"
              >
                <span className="contact__drawer--container__child--info__title">
                  {translate.CHILDREN_BIRTH_DATE} {index + 1}
                </span>
                <span className="contact__drawer--container__child--info__content">
                  {moment(get(_, "identity.user.birthDate")).format(
                    DATE_FORMAT
                  )}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default Information;
