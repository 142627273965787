import PropTypes from "prop-types";
import Button from "../Button";
import Header from "./Header";

const Component = ({ title, subtitle, actions, breadcrumb }) => (
  <Header
    title={title}
    subTitle={subtitle}
    headerRight={actions.map(
      ({ onClick, disabled, text, icon, type = "primary" }) => (
        <Button key={text} onClick={onClick} disabled={disabled} type={type}>
          {icon && <span className="icon">{icon}</span>}
          <span>{text}</span>
        </Button>
      )
    )}
    breadcrumb={breadcrumb}
  />
);

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.node,
      disabled: PropTypes.bool,
      type: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
    })
  ),
};

Component.defaultProps = {
  title: "",
  subtitle: "",
  actions: [],
};
export default Component;
