import Chart from "react-apexcharts";
const BasicBar = ({ data, height = 350 }) => {
  return (
    <Chart
      options={data.options}
      series={data.series}
      type="bar"
      height={height}
    />
  );
};

export default BasicBar;
