import { useLazyQuery } from "@apollo/client";
import { find, get, isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import React, { useState } from "react";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import View from "./View";

const ContactCard = ({ open, project, setOpen, contactId, tracer }) => {
  const [projects, setProjects] = useState({ count: 0, data: [] });
  const [isAssigning, setIsAssigning] = useState({});
  const [getContactProjects] = useLazyQuery(graphql.queries.PROJECTS, {
    fetchPolicy: "no-cache",
  });
  const onClose = () => {
    setOpen(false);
  };
  const { data, loading } = useQuery(graphql.queries.CONTACT, {
    variables: { where: { id: contactId } },
    onCompleted: ({ contact }) => {
      const email = get(
        find(
          get(contact, "telecoms"),
          ({ system }) => system === CONTACT_POINT_SYSTEM.EMAIL
        ),
        "value"
      );
      if (isEmpty(email)) return setProjects({ count: 0, data: [] });
      getContactProjects({
        variables: {
          where: {
            contact: { telecoms: { value: email } },
          },
        },
        onCompleted: ({ projects }) =>
          setProjects(omitDeep(projects, "__typename")),
      });
    },
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {});
  const [active, setActive] = useState("INFORMATION");
  const onAssign = ({ projectId, authorId }) => {
    setIsAssigning({ [projectId]: true });
    updateProject({
      variables: {
        where: { id: projectId },
        data: { author: { id: authorId } },
      },
      onCompleted: () => setTimeout(() => setIsAssigning({}), 1000),
    });
  };

  return (
    <View
      drawer={open && !loading}
      onClose={onClose}
      project={project}
      active={active}
      setActive={setActive}
      contact={omitDeep(get(data, "contact"), "__typename")}
      projects={projects}
      onAssign={onAssign}
      isAssigning={isAssigning}
      tracer={tracer}
    />
  );
};

export default React.memo(ContactCard);
