import { DatePicker } from "antd";
import locale from "antd/locale/fr_FR";
import classnames from "classnames";
import Label from "shared/components/Label";

const Simple = ({
  label,
  errors,
  icon,
  touched,
  props,
  direction,
  required,
  ...rest
}) => {
  const formatDate = (event) => {
    const input = event.target;
    const inputValue = input.value.replace(/\D/g, '');
    let formattedDate = inputValue;
    if (inputValue.length > 2) formattedDate = `${inputValue.slice(0, 2)}/${inputValue.slice(2)}`;
    if (inputValue.length > 4) formattedDate = `${formattedDate.slice(0, 5)}/${inputValue.slice(4)}`;
    if (inputValue.length > 8) formattedDate = formattedDate.slice(0, 10);
    input.value = formattedDate;
  };
  return (
    <div
      className={classnames(
        "datepicker__wrapper",
        {
          __error: touched && errors,
        },
        direction
      )}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`datepicker__wrapper--field`}>
        <DatePicker
          locale={locale}
          {...props}
          className={classnames({ "disabled--input": rest.disabled })}
          {...rest}
          onKeyDown={formatDate}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
