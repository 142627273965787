import format from "string-template";
import Card from "./Card";

const EmailTemplate = ({
  lastName,
  firstName,
  translation,
  costs,
  adminPhoneNumber,
  adminEmail,
  adminFirstName,
  adminLastName,
}) => {
  return (
    <div className="quote-template__main-container">
      <div className="quote-template__header">
        <img
          src="https://www.radiance.fr/sites/default/files/2021-04/logo-radiance-mutuelle.png"
          alt="Radiance Mutuelle Logo"
        />
      </div>
      <div className="quote-template__container">
        <div className="quote-template__content">
          <p>{format(translation.HEADER, { firstName, lastName })}</p>
          <p>{translation.P1}</p>
          <p>{translation.P2} </p>
          <div className="quote-template__product-card--container">
            {costs?.map(({ id, insurancePlan, cost, currency }) => (
              <Card
                key={id}
                icon={insurancePlan?.icon}
                name={insurancePlan?.name}
                cost={cost}
                currency={currency}
                period={translation.CARD.PER_MONTH}
                perYear={translation.CARD.PER_YEAR}
                allIncluded={translation.CARD.ALL_INCLUDED}
                link={translation.CARD.LINK}
              />
            ))}
          </div>
          <p>{format(translation.P3, { phone: adminPhoneNumber })}</p>
          <br />
          <div className="quote-template__content">
            <p>{translation.WITH_SMILE}</p>
          </div>
          <div className="quote-template__info">
            <p className="quote-template__info--text">
              <span>{translation.YOUR_ADVISOR}</span>
            </p>
            <p className="quote-template__info--name">
              {adminFirstName} {adminLastName}
            </p>
            <p>{translation.RADIANCE}</p>
            <p>{adminEmail}</p>
            <p>{adminPhoneNumber}</p>
            <p>95 rue Vendôme 69006 Lyon</p>
          </div>
        </div>
      </div>
      <div className="quote-template__footer">
        <p className="quote-template__footer--title">{translation.RADIANCE}</p>
        <p>95 rue Vendôme 69006 Lyon</p>
      </div>
    </div>
  );
};

export default EmailTemplate;
